import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import urls, { categorySlug } from "../../../urls";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { colors } from "../../../utils/theme";
import { formatCategoryName } from "../../../helpers/formatters";
import FeedbackWidget from "./FeedbackWidget";

const MobileNavMenu = ({ closeMenu, setActiveCategory, openAccountDrawer }) => {
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const { t, i18n } = useTranslation();
  const categories = useSelector(
    (state) => state.firestoreReducer.ordered.categories,
  );

  return (
    <>
      <StyledNav>
        <ul>
          <li>
            <MenuLinkItem to={urls.shop} onClick={closeMenu}>
              {t("all_products")}
            </MenuLinkItem>
          </li>
          {!!categories &&
            categories.map((category) => (
              <li key={category.id}>
                {category.subcategories.length > 0 ? (
                  <MenuParentItem
                    onClick={() => setActiveCategory(category)}
                    aria-label={t("go_to_subcategories", {
                      category: formatCategoryName(
                        category,
                        i18n.language,
                      ).toLowerCase(),
                    })}
                  >
                    {formatCategoryName(category, i18n.language)}
                    <span className={"arrow-next"} />
                  </MenuParentItem>
                ) : (
                  <MenuLinkItem
                    to={categorySlug + category.slug}
                    onClick={closeMenu}
                  >
                    {formatCategoryName(category, i18n.language)}
                  </MenuLinkItem>
                )}
              </li>
            ))}
          <Divider />
          <li>
            <MenuLinkItem to={urls.winkel} onClick={closeMenu}>
              {t("store")}
            </MenuLinkItem>
          </li>
          <li>
            <MenuLinkItem to={urls.recipes} onClick={closeMenu}>
              {t("recipes")}
            </MenuLinkItem>
          </li>
          <li>
            <MenuLinkItem to={urls.about} onClick={closeMenu}>
              {t("about_us")}
            </MenuLinkItem>
          </li>
          <li>
            <MenuLinkItem to={urls.contact} onClick={closeMenu}>
              {t("contact")}
            </MenuLinkItem>
          </li>
          <Divider />
          <li className="menu-item-has-children">
            {!auth.isEmpty ? (
              <ul className="sub-menu">
                <li>
                  <MenuParentItem
                    onClick={() => {
                      closeMenu();
                      openAccountDrawer();
                    }}
                  >
                    {t("my_account")}
                    <span className={"arrow-next"} />
                  </MenuParentItem>
                </li>
                <li>
                  <FeedbackWidget />
                </li>
              </ul>
            ) : (
              <ul className="sub-menu">
                <li>
                  <MenuLinkItem to={urls.login} onClick={closeMenu}>
                    {t("login")}
                  </MenuLinkItem>
                </li>
                <li>
                  <MenuLinkItem to={urls.register} onClick={closeMenu}>
                    {t("register")}
                  </MenuLinkItem>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </StyledNav>
    </>
  );
};

const Divider = styled.div`
  border-bottom: 1px solid #d9d9d9;
  margin: 1rem 0;
`;

const MenuLinkItem = styled(Link)`
  display: block;
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 0.65rem 0;
  color: ${colors.menu_items};

  &.all-products {
    font-size: 16px;
    color: ${colors.primary};
  }
`;

const MenuParentItem = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 0.65rem 0;
  color: ${colors.menu_items};
  background: none;
  border: none;

  .arrow-next {
    display: block;
    width: 9px;
    height: 9px;
    transform: rotate(-45deg);
    border-bottom: 2px solid ${colors.menu_items};
    border-right: 2px solid ${colors.menu_items};
    transition: all 0.3s ease 0s;
    color: transparent;
  }
`;

const StyledNav = styled.nav`
  padding: 2rem 1rem 2rem 2rem;
  position: relative;
`;

export default MobileNavMenu;
