import React from "react";

const BreadcrumbContainer = ({ children }) => {
  return (
    <section className="breadcrumb-area">
      <div
        className="container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {children}
      </div>
    </section>
  );
};

export default BreadcrumbContainer;
