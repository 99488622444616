import PropTypes from "prop-types";
import React from "react";
import { isMobile } from "../../helpers/browser";
import ProductGridListShop from "./ProductGridListShop";

const ShopProducts = ({ products }) => {
  return (
    <div className={`shop-bottom-area ${isMobile ? `mt-15` : `mt-25`}`}>
      <div className={"row"}>
        <ProductGridListShop products={products} />
      </div>
    </div>
  );
};

ShopProducts.propTypes = {
  products: PropTypes.array,
};

export default ShopProducts;
