import urls from "../urls";
import {
  EnvironmentOutlined,
  HeartOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";

export const FooterNavItems = [
  {
    label: "about_us",
    url: urls.about,
  },
  {
    label: "recipes",
    url: urls.recipes,
  },
  {
    label: "catering",
    url: urls.horeca,
  },
  {
    label: "packaging",
    url: urls.packaging,
  },
  {
    label: "vacancies",
    url: urls.vacatures,
  },
  {
    label: "contact",
    url: urls.contact,
  },
  {
    label: "returns",
    url: urls.delivery_returns,
  },
  {
    label: "faq",
    url: urls.faq,
  },
];

export const AccountNavItems = [
  {
    key: "my_details",
    url: urls.my_account,
    icon: <UserOutlined />,
  },
  {
    key: "my_addresses",
    url: urls.my_addresses,
    icon: <EnvironmentOutlined />,
  },
  {
    key: "my_orders",
    url: urls.my_orders,
    icon: <ShoppingOutlined />,
  },
  {
    key: "my_favorites",
    url: urls.favorites,
    icon: <HeartOutlined />,
  },
];
