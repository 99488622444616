import { useTranslation } from "react-i18next";
import { useLockScroll } from "../hooks/useLockScroll";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { AccountNavItems } from "../../configs/NavConfigs";
import { Button, notification } from "antd";
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { colors } from "../../utils/theme";
import { useFirebase } from "react-redux-firebase";

export default function MobileAccountDrawer({ active }) {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.firebaseReducer.profile);
  const { pathname } = useLocation();
  const firebase = useFirebase();
  const history = useHistory();

  useLockScroll(active);

  const handleSignOut = (e) => {
    e.preventDefault();
    firebase.logout().then(() => {
      notification["success"]({
        message: t("notifications.logged_out"),
      });
      history.push("/");
    });
  };

  return (
    <>
      <AnimatePresence>
        {active && (
          <Wrapper
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{
              duration: 0.4,
              ease: [0.77, 0.2, 0.05, 1],
              type: "tween",
            }}
          >
            <ContentContainer>
              <div className={"header-container"}>
                <h2>{t("my_account")}</h2>
                {!!user?.firstName && !!user?.lastName && (
                  <p>
                    {user?.firstName} {user?.lastName}
                  </p>
                )}
                {!!user?.email && <p>{user?.email}</p>}
              </div>
              <AccountNav>
                <ul>
                  {AccountNavItems.map((item) => (
                    <li key={item.key}>
                      <AccountLink
                        to={item.url}
                        className={pathname === item.url ? "active" : ""}
                      >
                        {item.icon}
                        {t(item.key)}
                      </AccountLink>
                    </li>
                  ))}
                </ul>
                <Button
                  type="default"
                  onClick={(e) => handleSignOut(e)}
                  className={"logout-button"}
                >
                  {t("logout")}
                </Button>
              </AccountNav>
            </ContentContainer>
          </Wrapper>
        )}
      </AnimatePresence>
    </>
  );
}

const Wrapper = styled(motion.div)`
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100svh;
  background: #fff;
  /* to stop flickering of text in safari */
  -webkit-font-smoothing: antialiased;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem 1rem 2rem;
  position: relative;

  .header-container {
    h2 {
      font-size: 2rem;
    }
    p {
      margin: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
      color: ${colors.menu_items};
    }
  }
`;

const AccountNav = styled.div`
  padding: 2rem 0.5rem;

  .logout-button {
    margin-top: 1rem;
  }
`;

const AccountLink = styled(Link)`
  display: flex;
  gap: 0.5rem;
  color: ${colors.menu_items};
  border-radius: 0;
  padding: 0.5rem;
  background-color: transparent;
  transition: background-color 0.3s ease;
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: 500;

  &.active {
    color: ${colors.primary};
    background-color: ${colors.primary10};
  }
`;
