import { useSelector } from "react-redux";
import styled from "styled-components";
import { colors } from "../../../utils/theme";
import { Link, useHistory } from "react-router-dom";
import urls from "../../../urls";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { device } from "../../../utils/breakpoints";
import { AccountNavItems } from "../../../configs/NavConfigs";
import { Button, notification } from "antd";
import { useFirebase } from "react-redux-firebase";

export default function AccountDropdown() {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const [expanded, setExpanded] = useState(false);
  const firebase = useFirebase();
  const history = useHistory();

  const handleSignOut = (e) => {
    e.preventDefault();
    firebase.logout().then(() => {
      notification["success"]({
        message: t("notifications.logged_out"),
      });
      history.push("/");
    });
  };

  return (
    <>
      <AccountDropdownWrapper
        onMouseEnter={() => setExpanded(true)}
        onMouseLeave={() => setExpanded(false)}
      >
        {auth.isEmpty ? (
          <>
            <button className={"icon-button"}>
              <AccountIcon />
            </button>
            <Dropdown className={expanded ? "expanded" : ""}>
              <ul>
                <li>
                  <Link to={urls.login}>{t("login")}</Link>
                </li>
                <li>
                  <Link to={urls.register}>{t("register")}</Link>
                </li>
              </ul>
            </Dropdown>
          </>
        ) : (
          <>
            <Link to={urls.my_account}>
              <AccountIcon />
            </Link>
            <Dropdown className={expanded ? "expanded" : ""}>
              <p>{t("my_account")}</p>
              <ul>
                {AccountNavItems.map((item) => (
                  <li key={item.key}>
                    <Link to={item.url}>
                      {item.icon}
                      {t(item.key)}
                    </Link>
                  </li>
                ))}
                <li>
                  <Button
                    type="default"
                    size="small"
                    block
                    onClick={(e) => handleSignOut(e)}
                    className={"logout-button"}
                  >
                    {t("logout")}
                  </Button>
                </li>
              </ul>
            </Dropdown>
          </>
        )}
      </AccountDropdownWrapper>
    </>
  );
}

const AccountIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: #333;
  -webkit-mask: url("/assets/img/button-icons/account.svg") no-repeat center;
  mask: url("/assets/img/button-icons/account.svg") no-repeat center / contain;
  transition: background-color 0.2s ease;
`;

const AccountDropdownWrapper = styled.div`
  position: relative;
  transition: all 0.2s ease;

  a {
    display: block;
    padding: 1px 6px;
  }

  button.icon-button {
    border: none;
    background: none;
  }

  &:hover {
    ${AccountIcon} {
      background-color: ${colors.primary};
    }
  }
`;

const Dropdown = styled.div`
  position: absolute;
  z-index: 220;
  top: 100%;
  right: 0;
  visibility: hidden;
  width: max-content;
  padding: 8px 12px 10px;
  transition: all 0.5s ease 0s;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  text-align: left;
  opacity: 0;
  background: #fff;
  box-shadow: 0 1px 7px -2px rgb(0 0 0 / 30%);

  &.expanded {
    visibility: visible;
    opacity: 1;
    transform: rotateX(0deg);
  }

  p {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 2px;
  }

  a {
    color: ${colors.heading};
    font-size: 13px;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      transition: none;
    }

    &:hover {
      color: ${colors.primary};
    }
  }

  .logout-button {
    font-size: 13px;
    margin-top: 8px;
  }

  @media ${device.xxl} {
    right: unset;
  }
`;
