import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Logo = ({ imageUrl, logoClass, closeMenu }) => {
  return (
    <LogoContainer
      className={`${logoClass ? logoClass : ""}`}
      onClick={closeMenu}
    >
      <Link to={"/"}>
        <img
          alt="logo"
          src={imageUrl}
          style={{ width: "100%", objectFit: "contain" }}
        />
      </Link>
    </LogoContainer>
  );
};

const LogoContainer = styled.div`
  overflow: hidden;

  a {
    display: block;
    height: 100%;
    width: fit-content;
  }
`;

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string,
};

export default Logo;
