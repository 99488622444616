import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import { deleteFromCart } from "../../redux/actions/cartActions";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import styled from "styled-components";
import { colors } from "../../utils/theme";
import { device } from "../../utils/breakpoints";
import LanguageChanger from "./sub-components/LanguageChanger";
import AccountDropdown from "./sub-components/AccountDropdown";

const IconGroup = ({
  cartData,
  wishlistData,
  deleteFromCart,
  iconWhiteClass,
  auth,
  mobile = false,
}) => {
  const { t } = useTranslation();
  const menuCartRef = useRef();
  const cartBtnRef = useRef();

  useEffect(() => {
    document.addEventListener("click", handleScreenClick);
    return () => document.removeEventListener("click", handleScreenClick);
  }, []);

  /** Close cart modal if it is open and user clicks outside of the element*/
  const handleScreenClick = (e) => {
    // Close Cart Modal if opened
    if (!!menuCartRef.current && !!cartBtnRef.current) {
      if (
        !!menuCartRef.current &&
        !menuCartRef.current.contains(e.target) &&
        !cartBtnRef.current.contains(e.target) &&
        menuCartRef.current.className.includes("active") &&
        e.target.id !== "delete-cart-item"
      ) {
        menuCartRef.current.classList.remove("active");
      }
    }
  };

  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu",
    );
    offcanvasMobileMenu.classList.add("active");
  };

  let cartItemsAmount = _.sumBy(cartData, "quantity");

  return (
    <IconWrapper className={`header-right-wrap `}>
      <LanguageChanger small />
      <AccountDropdown />
      <CartDropdown className="same-style cart-wrap d-none d-lg-block">
        <button
          className="icon-cart"
          onClick={(e) => handleClick(e)}
          ref={cartBtnRef}
        >
          <CartIcon />
          <Counter>
            <span>{cartItemsAmount}</span>
          </Counter>
        </button>
        {/* menu cart */}
        <MenuCart
          cartData={cartData}
          deleteFromCart={deleteFromCart}
          reference={menuCartRef}
        />
      </CartDropdown>
    </IconWrapper>
  );
};

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 14px;
`;

const CartDropdown = styled.div`
  position: relative;
`;

const Counter = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 21px;
  position: absolute;
  top: -12px;
  right: -9px;
  width: 20px;
  height: 20px;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  background-color: ${colors.primary};

  span {
    align-self: center;
  }
`;

const CartIcon = styled.div`
  width: 25px;
  height: 25px;
  background-color: #333;
  -webkit-mask: url("/assets/img/button-icons/cart.svg") no-repeat center;
  mask: url("/assets/img/button-icons/cart.svg") no-repeat center / contain;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${colors.primary};
  }

  @media ${device.lg} {
    width: 32px;
    height: 32px;
  }
`;

IconGroup.propTypes = {
  cartData: PropTypes.array,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
  wishlistData: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    cartData: state.cartData,
    wishlistData: state.wishlistData,
    auth: state.firebaseReducer.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFromCart: (item) => {
      dispatch(deleteFromCart(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);
