import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect, useSelector } from "react-redux";
import { addToCart } from "../../redux/actions/cartActions";
import { addToWishlist } from "../../redux/actions/wishlistActions";
import { addToCompare } from "../../redux/actions/compareActions";
import ProductGridShop from "../../components/product/ProductGridShop";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { LIST_TYPE_FEATURED } from "../../constants/FeaturedProductsConstants";
import { Row } from "react-bootstrap";

const ProductGridListShop = ({
  products,
  addToCart,
  addToWishlist,
  addToCompare,
  cartItems,
  wishlistItems,
  compareItems,
  sliderClassName,
  spaceBottomClass,
  showDeleteFromWishlistButton = false,
}) => {
  const { t } = useTranslation();
  const featuredProductsLists = useSelector(
    (state) => state.firestoreReducer.ordered.featuredProducts,
  );
  const featuredList = featuredProductsLists.find(
    (item) => item.type === LIST_TYPE_FEATURED,
  );

  return (
    <Fragment>
      {_.isEmpty(products) ? (
        <div className="ml-3 mt-3">
          <span className="lead">{t("product_not_found")}</span>
        </div>
      ) : (
        <Row className={"mr-0 ml-0"}>
          {products.map((product) => {
            let isFeaturedProduct = false;
            if (!!featuredList && !!featuredList.products) {
              if (
                featuredList.products.length > 0 &&
                featuredList.products.includes(product?.id)
              )
                isFeaturedProduct = true;
            }
            if (!!product)
              return (
                <ProductGridShop
                  sliderClassName={sliderClassName}
                  product={product}
                  addToCart={addToCart}
                  addToWishlist={addToWishlist}
                  addToCompare={addToCompare}
                  cartItems={cartItems}
                  wishlistItem={
                    wishlistItems.filter(
                      (wishlistItem) => wishlistItem.id === product.id,
                    )[0]
                  }
                  compareItem={
                    compareItems.filter(
                      (compareItem) => compareItem.id === product.id,
                    )[0]
                  }
                  isFeatured={isFeaturedProduct}
                  key={product?.id}
                  showDeleteFromWishlistButton={showDeleteFromWishlistButton}
                />
              );
            else return null;
          })}
        </Row>
      )}
    </Fragment>
  );
};

ProductGridListShop.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  products: PropTypes.array,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItems: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
    wishlistItems: state.wishlistData,
    compareItems: state.compareData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item, quantityCount, variation, type) => {
      dispatch(addToCart(item, quantityCount, variation, type));
    },
    addToWishlist: (item) => {
      dispatch(addToWishlist(item));
    },
    addToCompare: (item) => {
      dispatch(addToCompare(item));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductGridListShop);
